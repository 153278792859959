import React, { useState, useEffect, useRef } from 'react';
import { List, Input, Button, Card, Avatar, Badge, Tooltip, Popconfirm } from 'antd';
import io from 'socket.io-client';
import axios from 'axios';
import { DeleteFilled, UserOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';

const socket = io('https://api.metaxols.com');

const Chat = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const messagesEndRef = useRef(null);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [allMessages, setAllMessages] = useState({}); 
  const selectedEmployeeRef = useRef(selectedEmployee);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getCurrentUser();
    fetchEmployees();

    return () => {
      socket.off('receiveMessage');
      socket.off('users_status');
    };
  }, []); 

  useEffect(() => {
    if (currentUser && currentUser.userId) {
      setupSocketListeners();
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedEmployee && currentUser) {
      fetchMessages(selectedEmployee._id);
    }
  }, [selectedEmployee, currentUser]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    socket.on('users_status', (users) => {
      setOnlineUsers(new Set(users));
    });
    return () => socket.off('users_status');
  }, []);

  useEffect(() => {
    selectedEmployeeRef.current = selectedEmployee;
  }, [selectedEmployee]);

  useEffect(() => {
    const cleanUserId = currentUser?.userId;
    if (cleanUserId) {
      const handleReceiveMessage = (message) => {
        console.log('Received message:', message);
        
        if (message.receiver !== cleanUserId && message.sender !== cleanUserId) {
          return;
        }

        const chatPartnerId = message.sender === cleanUserId ? message.receiver : message.sender;

        // Update messages if currently chatting with the sender/receiver
        if (selectedEmployeeRef.current?._id === chatPartnerId) {
          setMessages(prev => {
            if (prev.some(m => m._id === message._id)) return prev;
            return [...prev, message];
          });
        } else if (message.receiver === cleanUserId) {
          // Increment unread message count
          setUnreadMessages(prev => {
            const currentCount = prev[message.sender] || 0;
            return {
              ...prev,
              [message.sender]: currentCount + 1
            };
          });
        }

        // Update allMessages state
        setAllMessages(prev => {
          const existingMessages = prev[chatPartnerId] || [];
          if (existingMessages.some(m => m._id === message._id)) return prev;
          return {
            ...prev,
            [chatPartnerId]: [...existingMessages, message]
          };
        });
      };

      socket.on('receiveMessage', handleReceiveMessage);

      return () => {
        socket.off('receiveMessage', handleReceiveMessage);
      };
    }
  }, [currentUser]);

  const getCurrentUser = () => {
    const userId = localStorage.getItem('user'); 
    if (userId) {
      const cleanUserId = userId.replace(/['"]+/g, '');
      setCurrentUser({
        userId: cleanUserId, // Use cleanUserId here
      });
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('https://api.metaxols.com/employee');
      const userId = localStorage.getItem('user'); 
      const filteredEmployees = response.data.data.filter(emp => emp._id !== userId);
      setEmployees(filteredEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const isMessageForChat = (message, chatId, userId) => {
    return (message.sender === chatId && message.receiver === userId) || 
           (message.receiver === chatId && message.sender === userId);
  };

  const setupSocketListeners = () => {
    const cleanUserId = currentUser.userId;
    if (cleanUserId) {
      socket.emit('registerUser', cleanUserId);
      // Remove 'socket.on('receiveMessage', ...)' from here
    }
  };

  const fetchMessages = async (employeeId) => {
    if (!currentUser?.userId) return;
    
    try {
      const cleanUserId = currentUser.userId.replace(/['"]+/g, '');
      const response = await axios.get(`https://api.metaxols.com/messages/${cleanUserId}/${employeeId}`);
      
      setMessages(response.data);
      setAllMessages(prev => ({
        ...prev,
        [employeeId]: response.data
      }));
      
      // Reset unread counter when opening chat
      setUnreadMessages(prev => ({
        ...prev,
        [employeeId]: 0
      }));
      
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !selectedEmployee) return;

    const cleanUserId = currentUser.userId; // Use cleanUserId from state
    
    // Find current user's image from employees list
    const currentUserEmployee = employees.find(emp => emp._id === cleanUserId);
    
    const messageData = {
      sender: cleanUserId,
      receiver: selectedEmployee._id,
      content: newMessage.trim(),
      senderImage: currentUserEmployee?.image || '', // Add sender's image
      timestamp: new Date(), // Add timestamp here
      chatId: `${cleanUserId}-${selectedEmployee._id}` // Add unique chat identifier
    };

    try {
      const response = await axios.post('https://api.metaxols.com/message', messageData);
      if (response.data) {
        if (selectedEmployee._id === messageData.receiver) {
          const newMsg = response.data;
          setMessages(prev => [...prev, newMsg]);
          
          setAllMessages(prev => ({
            ...prev,
            [selectedEmployee._id]: [...(prev[selectedEmployee._id] || []), newMsg]
          }));
        }

        socket.emit('private_message', {
          ...response.data,
          chatId: messageData.chatId,
          targetReceiver: selectedEmployee._id
        });
        
        setNewMessage('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const deleteMessage = async (messageId) => {
    try {
      await axios.delete(`https://api.metaxols.com/message/${messageId}`);
      setMessages(prev => prev.filter(msg => msg._id !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const deleteEntireChat = async (employeeId) => {
    try {
      const userId = localStorage.getItem('user')?.replace(/['"]+/g, '');
      await axios.delete(`https://api.metaxols.com/messages/chat/${userId}/${employeeId}`);
      setMessages([]);
      // Optionally close the chat
      setSelectedEmployee(null);
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  const renderMessages = () => {
    const userId = localStorage.getItem('user')?.replace(/['"]+/g, '');
    
    return messages.map((message, index) => {
      const isMyMessage = message.sender === userId;
      const senderEmployee = employees.find(emp => emp._id === message.sender);
      
      return (
        <div
          key={message._id || index}
          className={`flex ${isMyMessage ? 'justify-end' : 'justify-start'} mb-4 group`}
        >
          <div className={`flex items-start max-w-[70%] ${isMyMessage ? 'flex-row-reverse' : 'flex-row'}`}>
            {!isMyMessage && (
              <Avatar 
                src={message.senderImage || senderEmployee?.image} // Use message senderImage or fallback to employee image
                icon={<UserOutlined />} 
                className={`${isMyMessage ? 'ml-2' : 'mr-2'} mt-1`}
              />
            )}
            <div
              className={`px-4 py-2 rounded-lg shadow-sm relative ${
                isMyMessage
                  ? 'bg-blue-500 text-white rounded-br-none hover:bg-blue-600'
                  : 'bg-white text-gray-800 rounded-bl-none hover:bg-gray-50'
              }`}
            >
              <p className="m-0 break-words pr-6">{message.content}</p>
              <span className={`text-xs ${isMyMessage ? 'text-blue-100' : 'text-gray-500'}`}>
                {new Date(message.timestamp).toLocaleTimeString([], { 
                  hour: '2-digit', 
                  minute: '2-digit' 
                })}
              </span>
              
              {isMyMessage && (
                <Popconfirm
                  title="Delete message?"
                  onConfirm={() => deleteMessage(message._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined 
                    className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 
                             transition-opacity cursor-pointer text-white hover:text-red-200" 
                  />
                </Popconfirm>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderEmployeeItem = (employee) => {
    // Get latest message from allMessages
    const employeeMessages = allMessages[employee._id] || [];
    const latestMessage = employeeMessages[employeeMessages.length - 1];

    return (
      <List.Item 
        onClick={() => {
          setSelectedEmployee(employee);
          fetchMessages(employee._id);
        }}
        className={`cursor-pointer hover:bg-gray-50 transition-colors ${
          selectedEmployee?._id === employee._id ? 'bg-blue-50' : ''
        }`}
      >
        <List.Item.Meta
          avatar={
            <Badge 
              dot={unreadMessages[employee._id] > 0}
              offset={[-5, 1]} // Adjust offset if necessary
              color="green"
              size={16}
            >
              <div className="relative">
                <Avatar 
                  icon={<UserOutlined />}
                  src={employee.image}
                  className="bg-blue-200"
                />
                {onlineUsers.has(employee._id) && (
                  <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></span>
                )}
              </div>
            </Badge>
          }
          title={
            <div className="flex items-center">
              <span className="font-medium">{employee.name}</span>
              {onlineUsers.has(employee._id) && (
                <Tooltip title="Online">
                  <span className="ml-2 w-2 h-2 bg-green-500 rounded-full"></span>
                </Tooltip>
              )}
            </div>
          }
          description={
            <div className="text-xs text-gray-500">
              {latestMessage ? (
                <>
                  <span className="block truncate">{latestMessage.content}</span>
                  <span>{new Date(latestMessage.timestamp).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}</span>
                </>
              ) : (
                <span>Start a conversation</span>
              )}
            </div>
          }
        />
      </List.Item>
    );
  };

  const renderChatHeader = () => (
    <div className="p-4 border-b border-gray-200 bg-white">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Avatar 
            icon={<UserOutlined />}
            src={selectedEmployee?.image}
            className="mr-3"
          />
          <div>
            <h2 className="text-lg font-semibold m-0">{selectedEmployee?.name}</h2>
            <p className="text-sm text-gray-500 m-0">{selectedEmployee?.position}</p>
          </div>
        </div>
        <Popconfirm
          title="Delete entire chat?"
          description="This action cannot be undone."
          onConfirm={() => deleteEntireChat(selectedEmployee?._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ danger: true }}
        >
          <Button 
            icon={<DeleteFilled />} 
            danger
            className="hover:bg-red-50"
          >
            Delete Chat
          </Button>
        </Popconfirm>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row h-[calc(100vh-64px)] bg-gray-50 px-4 md:px-6 py-4">
      {/* Employee List - Left Sidebar */}
      <div className="w-full md:w-1/4 lg:w-1/5 border-r border-gray-200 bg-white rounded-l-lg shadow-sm">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Messages</h2>
        </div>
        <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
          <List
            className="employee-list"
            dataSource={employees}
            renderItem={renderEmployeeItem}
          />
        </div>
      </div>

      {/* Chat Area - Right Side */}
      <div className="flex-1 flex flex-col bg-white rounded-r-lg shadow-sm ml-0 md:ml-4">
        {selectedEmployee ? (
          <>
            {renderChatHeader()}
            {/* Messages Area */}
            <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
              <div className="max-w-4xl mx-auto">
                {renderMessages()}
                <div ref={messagesEndRef} />
              </div>
            </div>

            {/* Message Input */}
            <div className="p-4 border-t border-gray-200 bg-white">
              <div className="flex gap-2">
                <Input
                  placeholder="Type a message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onPressEnter={handleSendMessage}
                  className="flex-1"
                />
                <Button 
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={handleSendMessage}
                  className="bg-blue-500"
                >
                  Send
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-1 flex items-center justify-center bg-gray-50">
            <div className="text-center text-gray-500">
              <UserOutlined style={{ fontSize: '48px' }} />
              <p className="mt-2">Select a chat to start messaging</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;