import React, { useState, useEffect } from 'react';
import { Card, Button, Select, DatePicker, Modal, Input, message, Tag, Tooltip, Popconfirm } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import moment from 'moment';
import {FaClock, FaPlus, FaTrash, FaUserCheck} from 'react-icons/fa';
import {UserOutlined} from '@ant-design/icons';
import {RiEdit2Fill} from 'react-icons/ri';

const { Option } = Select;
const { TextArea } = Input;

const TaskComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [dueDate, setDueDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [isSubTaskModalVisible, setIsSubTaskModalVisible] = useState(false);

  const token = localStorage.getItem("token");
  const employeeId = localStorage.getItem("user")?.replace(/"/g, "");
  let isAdmin = false;
  if (token) {
      try {
          const payload = JSON.parse(atob(token?.split('.')[1]));
          isAdmin = payload.isAdmin;
      } catch (error) {
          console.error("Error decoding token:", error);
      }
  }

  useEffect(() => {
    fetchTasks();
    if (isAdmin) {
      fetchEmployees();
    }
  }, [isAdmin]);

  const fetchTasks = async () => {
    try {
      const url = isAdmin 
        ? 'https://api.metaxols.com/task/all'
        : `https://api.metaxols.com/task/employee/${employeeId}`;
      const response = await axios.get(url);
  
      // Directly set tasks from the response since `assignedTo` now contains objects
      setTasks(response.data.tasks);
    } catch (error) {
      message.error('Failed to fetch tasks');
    }
  };
  const handleEditTask = (task) => {
    setCurrentTaskId(task._id);
    setTitle(task.title);
    setDescription(task.description);
    setSelectedEmployees(task.assignedTo.map(emp => emp._id));
    setDueDate(moment(task.dueDate));
    setIsEditMode(true);
    setIsModalVisible(true);
  };
  const handleDeleteTask = async (taskId) => {
    try {
      await axios.delete(`https://api.metaxols.com/task/delete/${taskId}`);
      message.success('Task deleted successfully');
      fetchTasks();
    } catch (error) {
      message.error('Failed to delete task');
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('https://api.metaxols.com/employee');
      setEmployees(response.data.data);
    } catch (error) {
      message.error('Failed to fetch employees');
    }
  };

  const handleCreateTask = async () => {
    try {
      setLoading(true);
      await axios.post('https://api.metaxols.com/task/create', {
        title,
        description,
        assignedTo: selectedEmployees,
        assignedBy: employeeId,
        dueDate: dueDate.toDate()
      });
      message.success('Task created successfully');
      setIsModalVisible(false);
      fetchTasks();
      resetForm();
    } catch (error) {
      message.error('Failed to create task');
    } finally {
      setLoading(false);
    }
  };


  

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setSelectedEmployees([]);
    setDueDate(null);
  };

  const handleupdateTaskStatus = async (taskId, newStatus) => {
    try {
      await axios.put(`https://api.metaxols.com/task//update/${taskId}`, { status: newStatus });
      message.success('Task status updated successfully');
      fetchTasks(); // Refresh tasks to reflect updates
    } catch (error) {
      message.error('Failed to update task status');
    }
  };
  
  const getStatusColor = (status) => {
    const colors = {
      pending: 'gold',
      ongoing: 'blue',
      completed: 'green'
    };
    return colors[status] || 'default';
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
    <div className="max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">
          {isAdmin ? 'Task Management Dashboard' : 'My Task Dashboard'}
        </h1>
        {isAdmin && (
          <Button
            onClick={() => {
              setIsEditMode(false);
              setIsModalVisible(true);
              resetForm();
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg flex items-center gap-2"
          >
            <FaPlus size={20} /> Create New Task
          </Button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {tasks.map(task => (
            <Card key={task._id} className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h2 className="text-xl font-semibold text-gray-800">{task.title}</h2>
                  {isAdmin && (
                    <div className="flex gap-2">
                      <Tooltip title="Edit Task">
                        <Button
                          onClick={() => handleEditTask(task)}
                          className="p-2 text-blue-600 hover:bg-blue-50 rounded-full"
                        >
                          <RiEdit2Fill size={16} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Task">
                        <Popconfirm
                          title="Are you sure you want to delete this task?"
                          onConfirm={() => handleDeleteTask(task._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className="p-2 text-red-600 hover:bg-red-50 rounded-full">
                            <FaTrash size={16} />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="space-y-4">
                  <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: task.description }} />
                  
                  <div className="flex items-center gap-2 text-gray-600">
                    <FaClock size={16} />
                    <span>Due: {moment(task.dueDate).format('MMMM Do YYYY')}</span>
                  </div>

                  <div className="flex items-center gap-2 text-gray-600">
                    {isAdmin ? (
                      <>
                        <FaUserCheck size={16} />
                        <span>Assigned to: {task.assignedTo.map(emp => emp.name).join(', ')}</span>
                      </>
                    ) : (
                      <>
                        <UserOutlined size={16} />
                        <span>Assigned by: {task?.assignedBy?.name }</span>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                      task.status === 'completed' ? 'bg-green-100 text-green-800' :
                      task.status === 'ongoing' ? 'bg-blue-100 text-blue-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {task.status.charAt(0).toUpperCase() + task.status.slice(1)}
                    </div>

                    {!isAdmin && task.status !== 'completed' && (
                      <Select
                        value={task.status}
                        onChange={(newStatus) => handleupdateTaskStatus(task._id, newStatus)}
                        className="min-w-[140px]"
                      >
                        <Select.Option value="pending">Pending</Select.Option>
                        <Select.Option value="ongoing">In Progress</Select.Option>
                        <Select.Option value="completed">Completed</Select.Option>
                      </Select>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>

      <Modal
        title="Create New Task"
        open={isModalVisible}
        onOk={handleCreateTask}
        onCancel={() => {
          setIsModalVisible(false);
          resetForm();
        }}
        confirmLoading={loading}
      >
        <div className="space-y-4">
          <Input
            placeholder="Task Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="h-32 mb-12"
          />
          <Select
            mode="multiple"
            placeholder="Assign to Employees"
            style={{ width: '100%' }}
            value={selectedEmployees}
            onChange={setSelectedEmployees}
          >
            {employees.map(emp => (
              <Option key={emp.employeeId} value={emp._id}>
                {emp.name}
              </Option>
            ))}
          </Select>
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Select Due Date"
            value={dueDate}
            onChange={setDueDate}
          />
        </div>
      </Modal>

    
    </div>
    </div>
  );
};

export default TaskComponent;
